import React from 'react'

export const FormUsuarioInformacionNuevo = () => {
  return (
    <form className='card'>
        <div className='card-body'>
            <h5>Formulario ingreso informacion usuario</h5>
            <div className="row mb-3 mt-3">
                <label htmlFor="telefono" className="col-2 col-form-label">Telefono</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="telefono"
                        name="telefono"
                        // value={ telefono }
                        // onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="telefono2" className="col-2 col-form-label">Telefono2</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="telefono2"
                        name="telefono2"
                        // value={ telefono2 }
                        // onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="pais" className="col-2 col-form-label">Pais</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="pais"
                        name="pais"
                        // value={ pais }
                        // onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="region" className="col-2 col-form-label">Region</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="region"
                        name="region"
                        // value={ region }
                        // onChange={ handleInputChange }
                    />
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <label htmlFor="ciudad" className="col-2 col-form-label">Ciudad</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="ciudad"
                        name="ciudad"
                        // value={ ciudad }
                        // onChange={ handleInputChange }
                    />
                </div>
                <label htmlFor="direccion" className="col-2 col-form-label">Direccion</label>
                <div className="col-4">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="direccion"
                        name="direccion"
                        // value={ direccion }
                        // onChange={ handleInputChange }
                    />
                </div>
            </div>
        </div>
    </form>
  )
}
