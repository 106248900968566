import React from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { crearCredencialUsuario } from '../../action/credencialesUsuarios';
import { usuarioTablaCredenciales } from '../../action/router';
import { useForm } from '../../hooks/useForm';

export const FormUsuarioCredencialNueva = ({ usuarioActivo, establecimientoActivo}) => {
    const dispatch = useDispatch();
    const [credencial, handleInputChange] = useForm({
        email: '',
        cargo: '',
        establecimiento: establecimientoActivo,
        usuario: usuarioActivo,
    });
    const { email, cargo } = credencial;

    const handleCrear = (e) => {
        e.preventDefault()
        if( email === '' || cargo === ''  ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(crearCredencialUsuario(credencial));
        dispatch(usuarioTablaCredenciales())
    }

    const handleCancelar = (e) =>{
        e.preventDefault()
        dispatch(usuarioTablaCredenciales())
    }


    return (
        <form className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso credencial usuario</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="email" className="col-2 col-form-label">Email</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="email"
                            name="email"
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="cargo" className="col-2 col-form-label">Cargo</label>
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={cargo}  
                        name="cargo" 
                    >
                        <option value="" defaultValue>Seleccione...</option>
                        <option value="1" defaultValue>Administrador</option>
                        <option value="2" defaultValue>Docente</option>
                        
                    </select>
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className='col-12'>
                    <div className='row'>
                        <div className="col-3">
                            <button 
                                type="submit" 
                                className="btn btn-primary" 
                                onClick={ handleCrear }
                            >Crear</button>
                        </div>
                        <div className="col-3">
                            <button 
                                type="submit" 
                                className="btn btn-danger" 
                                onClick={ handleCancelar }
                            >Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
