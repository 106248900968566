import React from 'react'
import { useDispatch } from 'react-redux'
import { usuarioFormCredencialNuevo } from '../../action/router'

export const BotonCredencialNueva = () => {
    const dispatch = useDispatch()

    const handleNuevo = () => {
        dispatch(usuarioFormCredencialNuevo())
    }
    return (
        <div className='d-grid gap-2'>
            <button className='btn btn-primary btn-sm' onClick={ handleNuevo } >Nueva Credencial</button>
        </div>
    )
}
