import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import  { useForm } from '../../hooks/useForm';
import Swal from 'sweetalert2';
import { crearUsuario } from '../../action/usuario';
import { clearUploadFile, startUploadingFile } from '../../helpers/thunks';
import { usuarioTablaUsuarios } from '../../action/router';

export const FormUsuarioNuevo = () => {
    const dispatch = useDispatch();
    const { file } = useSelector(state => state.file);
    const [usuario, handleInputChange] = useForm({
        rut: '',
        nombres: '',
        apellidoPaterno:'',
        apellidoMaterno:'',
        fechaNacimiento: '',
        password: '',
        fotografia: '',

    });
    const { rut, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento, password } = usuario;
    
    const handleEnviar = (e) => {
        e.preventDefault()
        if( rut === '' || nombres === '' || apellidoPaterno === '' || apellidoMaterno === '' || fechaNacimiento === '' || password === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        if(file){
            usuario.fotografia = file;
        }
        dispatch(crearUsuario(usuario));
        dispatch(clearUploadFile(false))
        dispatch(usuarioTablaUsuarios())
    }

    const handleUploadFile = (e) =>{
        Swal.fire({
            icon: 'warning',
            title: 'Subiendo fotografia espere...',
            showConfirmButton: false,
            timer: 4000
        });
        const file = e.target.files[0];
        if( file === 0 ) return;
        dispatch(startUploadingFile(file));
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso usuario</h5>
                <form>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="rut" className="col-2 col-form-label">Rut</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="rut"
                                name="rut"
                                value={ rut }
                                onChange={ handleInputChange }
                            />
                        </div>
                        <label htmlFor="nombres" className="col-2 col-form-label">Nombres</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="nombres"
                                name="nombres"
                                value={ nombres }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="apellidoPaterno" className="col-2 col-form-label">Apellido paterno</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="apellidoPaterno"
                                name="apellidoPaterno"
                                value={ apellidoPaterno }
                                onChange={ handleInputChange }
                            />
                        </div>
                        <label htmlFor="apellidoMaterno" className="col-2 col-form-label">Apellido materno</label>
                        <div className="col-4">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="apellidoMaterno"
                                name="apellidoMaterno"
                                value={ apellidoMaterno }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="fechaNacimiento" className="col-2 col-form-label">Fecha nacimiento</label>
                        <div className="col-4">
                            <input 
                                type="date" 
                                className="form-control" 
                                id="fechaNacimiento"
                                name="fechaNacimiento"
                                value={ fechaNacimiento }
                                onChange={ handleInputChange }
                            />
                        </div>
                        <label htmlFor="password" className="col-2 col-form-label">Contraseña</label>
                        <div className="col-4">
                            <input 
                                type="password" 
                                className="form-control" 
                                id="password"
                                name="password"
                                value={ password }
                                onChange={ handleInputChange }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <label htmlFor="fotografia" className="col-2 col-form-label">Fotografia</label>
                        <div className="col-5">
                            <input 
                                id="inputGroupFile01"
                                type="file" 
                                className="form-control" 
                                name="fotografia"
                                onChange={ handleUploadFile }
                            />
                        </div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4">
                            <button 
                                type="submit" 
                                className="btn btn-primary" 
                                onClick={ handleEnviar }
                            >Crear</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
