import Swal from "sweetalert2";
import { fetchConToken, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";



export const crearCredencialUsuario = ( credencialUsuario ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('establecimiento/crearCredencialUsuario', credencialUsuario, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearCredencialUsuarioOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCredencialUsuarioError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCredencialUsuarioError());
        }
    }
}

const crearCredencialUsuarioOk = (credencial) => ({
    type: types.crearCredencialUsuarioOk,
    payload: credencial
});

const crearCredencialUsuarioError = () => ({ type: types.crearCredencialUsuarioError });

export const obtenerCredencialesUsuarioEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerCredencialesUsuarioEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(obtenerCredencialesUsuarioOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerCredencialesUsuarioError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCredencialesUsuarioError());
        }
    }
}

export const obtenerCredencialesUsuario = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerCredencialesUsuario', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(obtenerCredencialesUsuarioOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerCredencialesUsuarioError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCredencialesUsuarioError());
        }
    }
}

const obtenerCredencialesUsuarioOk = (credenciales) => ({ 
    type: types.obtenerCredencialesUsuarioOk ,
    payload: credenciales
});

const obtenerCredencialesUsuarioError = () => ({ type: types.obtenerCredencialesUsuarioError })

export const actualizarCredencialUsuario = (credencial) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`establecimiento/actualizarCredencialUsuario/${credencial._id}`, credencial, 'PUT');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(actualizarCredencialUsuarioOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(actualizarCredencialUsuarioError());
            }

        } catch (error) {
            console.log(error)
            dispatch(actualizarCredencialUsuarioError())
        }
    }
}

const actualizarCredencialUsuarioOk = (credencial) => ({ 
    type: types.actualizarCredencialUsuarioOk,
    payload: credencial
});

const actualizarCredencialUsuarioError = () => ({ type : types.actualizarCredencialUsuarioError });

export const activarCredencialUsuario = (e) => ({ 
    type: types.activarCredencialUsuario,
    payload: e
});

export const limpiarCredencialesUsuario = () => ({ type: types.limpiarCredencialesUsuario });