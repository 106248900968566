import React from 'react'
import { useDispatch } from 'react-redux';
import { activarCredencialUsuario, actualizarCredencialUsuario } from '../../action/credencialesUsuarios';
import { usuarioFormCredencialActualizar } from '../../action/router';

export const RowCredencial = ({ credencial, usuario, establecimientos, establecimientoActivo, index }) => {
  const dispatch = useDispatch();
  const establecimientoPrincipal =  establecimientos.find(e=> e._id === establecimientoActivo);
  const { _id, email, cargo, estado } = credencial;

  
  const handleActivar = () => {
    credencial.estado = 'true'
    dispatch(actualizarCredencialUsuario(credencial))
  }

  const handleDesactivar = () => {
    credencial.estado = 'false'
      dispatch(actualizarCredencialUsuario(credencial))
  }

  const handleActualizar = () => {
    dispatch(activarCredencialUsuario(_id))
    dispatch(usuarioFormCredencialActualizar())
  }


  return (
    <tr>
      <th>{index}</th>
      <td>{usuario.nombres} {usuario.apellidoPaterno} {usuario.apellidoMaterno}</td>
      <td>{cargo}</td>
      <td>{email}</td>
      <td>{establecimientoPrincipal.nombre}</td>
      <td>
          {estado 
              ? 
                  <div className='d-grid gap-2'>
                      <button className='btn btn-success btn-sm' onClick={ handleDesactivar } >Activo</button>
                  </div>
              : 
                  <div className='d-grid gap-2'>
                      <button className='btn btn-danger btn-sm' onClick={ handleActivar } >Inactivo</button>
                  </div>
          }
      </td>
      <td>
          <div className='d-grid gap-2'>
              <button className='btn btn-warning btn-sm' onClick={ handleActualizar } >Actualizar</button>
          </div>
      </td>
    </tr>
  )
}
