import React from 'react'
import { useDispatch } from 'react-redux'
import { usuarioFormNuevo } from '../../action/router'

export const BotonNuevoUsuario = () => {
    const dispatch = useDispatch()

    const handleNuevo = () => {
        dispatch(usuarioFormNuevo())
    }
    return (
        <div className='d-grid gap-2'>
            <button className='btn btn-primary btn-sm' onClick={ handleNuevo } >Nuevo Usuario</button>
        </div>
    )
}
