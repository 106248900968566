import Swal from "sweetalert2";
import { types } from "../types/types";
import { fileUpload } from "./fileUpload";

export const startUploadingFile = ( file = [] ) => {
    return async ( dispatch ) => {
        const fileUrl = await fileUpload(file);
        Swal.fire({
            icon: 'success',
            title: 'Emblema cargado correctamente',
            showConfirmButton: false,
            timer: 1000
        });
        dispatch(startUploadingOk(fileUrl));
    }
}

export const startUploadingOk = (file) => ({
    type: types.startUploadingOk,
    payload: file
});

export const clearUploadFile = (file) => ({
    type: types.clearUploadFile,
    payload: file
})