import * as React from "react"
import { AdministradorUsuarioScreen } from "../components/administradorUsuario/AdministradorUsuarioScreen"
import Layout from '../components/layout'


const administradorUsuario = () => {
  return (
    <Layout>
      <div className="container-xl">
        <AdministradorUsuarioScreen  />
      </div>
    </Layout>
  )
}

export default administradorUsuario
