import Swal from "sweetalert2";
import { fetchConToken, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";



export const crearUsuario = ( usuario ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('usuario/crearUsuario', usuario, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearUsuarioOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearUsuarioError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearUsuarioError());
        }
    }
}

const crearUsuarioOk = (usuario) => ({
    type: types.crearUsuarioOk,
    payload: usuario
});

const crearUsuarioError = () => ({ type: types.crearUsuarioError });

export const obtenerUsuarios = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken('usuario/obtenerUsuarios');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(obtenerUsuariosOk( result.payload ))
               
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerUsuariosError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerUsuariosError());
        }
    }
}

const obtenerUsuariosOk = (usuarios) => ({ 
    type: types.obtenerUsuariosOk ,
    payload: usuarios
});

const obtenerUsuariosError = () => ({ type: types.obtenerUsuariosError })

export const actualizarUsuario = (usuario) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`usuario/actualizarUsuario/${usuario._id}`, usuario, 'PUT');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(actualizarUsuarioOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(actualizarUsuarioError());
            }

        } catch (error) {
            console.log(error)
            dispatch(actualizarUsuarioError())
        }
    }
}

const actualizarUsuarioOk = (usuario) => ({ 
    type: types.actualizarUsuarioOk,
    payload: usuario
});

const actualizarUsuarioError = () => ({ type : types.actualizarUsuarioError });

export const activarUsuario = (e) => ({ 
    type: types.activarUsuario,
    payload: e
});



export const obtenerDocentesEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('usuario/obtenerDocentesEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(obtenerDocentesOk( result.payload ))
               
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerDocentesError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerDocentesError());
        }
    }
}

const obtenerDocentesOk = (e) => ({ 
    type: types.obtenerDocentesOk ,
    payload: e
});

const obtenerDocentesError = () => ({ type: types.obtenerDocentesError })
