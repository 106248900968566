
import { types } from "../types/types";

//admin establecimientos
export const establecimientoFormNuevo = () => ({ type: types.establecimientoFormNuevo });
export const establecimientoFormActualizar = () => ({ type: types.establecimientoFormActualizar });
export const establecimientosTabla = () => ({ type: types.establecimientosTabla });
export const establecimientoContenido = () => ({ type: types.establecimientoContenido });
export const establecimientoFormInformacionActualizar = () => ({ type: types.establecimientoFormInformacionActualizar });

export const establecimientoNoticiasPrincipal = () => ({ type: types.establecimientoNoticiasPrincipal });
//noticias establecimiento
export const establecimientoTablaNoticias = () => ({ type: types.establecimientoTablaNoticias });
export const establecimientoFormNoticiaNueva = () => ({ type: types.establecimientoFormNoticiaNueva });
export const establecimientoFormNoticiaActualizar = () => ({ type: types.establecimientoFormNoticiaActualizar });
//categoria noticia establecimiento
export const establecimientoTablaCategoriaNoticias = () => ({ type: types.establecimientoTablaCategoriaNoticias });
export const establecimientoFormCategoriaNueva = () => ({ type: types.establecimientoFormCategoriaNueva });
export const establecimientoFormCategoriActualizar = () => ({ type: types.establecimientoFormCategoriActualizar });


// admin usuarios
export const usuarioTablaEstablecimientos = () => ({ type: types.usuarioTablaEstablecimientos });
export const usuarioTablaUsuarios = () => ({ type: types.usuarioTablaUsuarios });
export const usuarioFicha = () => ({ type: types.usuarioFicha });
export const usuarioFormNuevo = () => ({ type: types.usuarioFormNuevo });
export const usuarioFormActualizar = () => ({ type: types.usuarioFormActualizar });
export const usuarioTablaCredenciales = () => ({ type: types.usuarioTablaCredenciales });
export const usuarioFormCredencialNuevo = () => ({ type: types.usuarioFormCredencialNuevo });
export const usuarioFormCredencialActualizar = () => ({ type: types.usuarioFormCredencialActualizar });
export const usuarioFormInformacionNueva = () => ({ type: types.usuarioFormInformacionNueva });
export const usuarioFormInformacionActualizar = () => ({ type: types.usuarioFormInformacionActualizar });

// admin estudiantes
export const estudianteTablaEstablecimientos = () => ({ type: types.estudianteTablaEstablecimientos });
export const estudianteTablaEstudiantes = () => ({ type: types.estudianteTablaEstudiantes });
export const estudianteFicha = () => ({ type: types.estudianteFicha });
export const estudianteFormNuevo = () => ({ type: types.estudianteFormNuevo });
export const estudianteFormActualizar = () => ({ type: types.estudianteFormActualizar });
export const estudianteTablaCredenciales = () => ({ type: types.estudianteTablaCredenciales });
export const estudianteFormCredencialNueva = () => ({ type: types.estudianteFormCredencialNueva });
export const estudianteFormCredencialActualizar = () => ({ type: types.estudianteFormCredencialActualizar });
export const estudianteFormInformacionNueva = () => ({ type: types.estudianteFormInformacionNueva });
export const estudianteFormInformacionActualizar = () => ({ type: types.estudianteFormInformacionActualizar });

//libro digital
export const libroDigitalTablaEstablecimientos = () => ({ type: types.libroDigitalTablaEstablecimientos })
export const libroDigitalTablaAniosEscolares = () => ({ type: types.libroDigitalTablaAniosEscolares })
export const libroDigitalFormAnioEscolarNuevo = () => ({ type: types.libroDigitalFormAnioEscolarNuevo })
export const libroDigitalFormAnioEscolarActualizar = () => ({ type: types.libroDigitalFormAnioEscolarActualizar })
export const libroDigitalTablaPeriodosEscolares = () => ({ type: types.libroDigitalTablaPeriodosEscolares })
export const libroDigitalFormPeriodoEscolarNuevo = () => ({ type: types.libroDigitalFormPeriodoEscolarNuevo })
export const libroDigitalFormPeriodoEscolarActualizar = () => ({ type: types.libroDigitalFormPeriodoEscolarActualizar })
export const libroDigitalTablaPeriodosCurso = () => ({ type: types.libroDigitalTablaPeriodosCurso })
export const libroDigitalTablaCursos = () => ({ type: types.libroDigitalTablaCursos })
export const libroDigitalFormCursoNuevo = () => ({ type: types.libroDigitalFormCursoNuevo })
export const libroDigitalFormCursoActualizar = () => ({ type: types.libroDigitalFormCursoActualizar })
export const libroDigitalTablaAsignaturas = () => ({ type: types.libroDigitalTablaAsignaturas })
export const libroDigitalFormAsignaturaNueva = () => ({ type: types.libroDigitalFormAsignaturaNueva })
export const libroDigitalFormAsignaturaActualizar = () => ({ type: types.libroDigitalFormAsignaturaActualizar })
export const libroDigitalTablaTalleres = () => ({ type: types.libroDigitalTablaTalleres })
export const libroDigitalFormTallerNuevo = () => ({ type: types.libroDigitalFormTallerNuevo })
export const libroDigitalFormTallerActualizar = () => ({ type: types.libroDigitalFormTallerActualizar })
export const libroDigitalTablaEstudiantes = () => ({ type: types.libroDigitalTablaEstudiantes })
export const libroDigitalFormMatriculaNueva = () => ({ type: types.libroDigitalFormMatriculaNueva })
export const libroDigitalFormMatriculaActualizar = () => ({ type: types.libroDigitalFormMatriculaActualizar })
