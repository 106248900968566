import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { limpiarCredencialesUsuario, obtenerCredencialesUsuarioEstablecimiento } from '../../action/credencialesUsuarios'
import { usuarioTablaUsuarios } from '../../action/router'
import { activarUsuario } from '../../action/usuario'
import { Loading } from '../Loading'
import { RowSinData } from '../RowSinData'
import { RowCredencial } from './RowCredencial'

export const TablaCredenciales = ({usuarioActivo, credencialesUsuario, usuarios, establecimientos, establecimiento}) => {
    const dispatch = useDispatch();
    
    const usuarioPrincial = usuarios.find(e => e._id === usuarioActivo)
    useEffect(() => {
        // if(usuarioActivo !== ''){
        const filtro = {
            establecimiento: establecimiento,
            usuario: usuarioActivo
        }
        const obtenerCredencialesUsuariosPrincipal = () => dispatch(obtenerCredencialesUsuarioEstablecimiento(filtro));
        obtenerCredencialesUsuariosPrincipal()
        // }
        // eslint-disable-next-line
    }, [usuarioActivo]);

    const handleRegresar = () => {
        dispatch(limpiarCredencialesUsuario())
        dispatch(activarUsuario(""))
        dispatch(usuarioTablaUsuarios())
    }

    if(!credencialesUsuario) return <Loading />
    return (
        <div className='card'>
            <div className='card-body'>
                <h6>Tabla Credenciales Usuario</h6>
                <table className='table table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Usuario</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Email</th>
                            <th scope="col">Establecimiento</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Actualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {credencialesUsuario.length > 0 
                            ?
                            credencialesUsuario.map((e, index)=>(
                                <RowCredencial 
                                    key={e._id}
                                    credencial={e}
                                    usuario={ usuarioPrincial }
                                    establecimientos={establecimientos}
                                    establecimientoActivo={establecimiento}
                                    index={ index + 1 }
                                />
                            ))
                            :   
                            <RowSinData />
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <button className='btn btn-danger' onClick={handleRegresar}>Regresar</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}