import React from 'react'
import { useDispatch } from 'react-redux';
import { usuarioFicha, usuarioFormActualizar, usuarioTablaCredenciales } from '../../action/router';
import { activarUsuario, actualizarUsuario } from '../../action/usuario';

export const RowUsuario = ({usuario, index}) => {
    const dispatch = useDispatch()
    const {nombres, rut, apellidoPaterno, apellidoMaterno, fechaNacimiento, estado } = usuario;
    
    const handleActivar = () => {
        usuario.estado = 'true'
        dispatch(actualizarUsuario(usuario))
    }

    const handleDesactivar = () => {
        usuario.estado = 'false'
        dispatch(actualizarUsuario(usuario))
    }
    const handleFicha = () => {
        dispatch(activarUsuario(usuario._id))
        dispatch(usuarioFicha())
    }
    const handleCredenciales = () => {
        dispatch(activarUsuario(usuario._id))
        dispatch(usuarioTablaCredenciales())
    }
    const handleModificar = () => {
        dispatch(activarUsuario(usuario._id))
        dispatch(usuarioFormActualizar())
    }

    return (
        <tr>
            <th>{index}</th>
            <td>{rut}</td>
            <td>{`${nombres} ${apellidoPaterno} ${apellidoMaterno}`}</td>
            <td>{fechaNacimiento}</td>
            <td>
                {estado 
                    ? 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-success btn-sm' onClick={ handleDesactivar } >activo</button>
                        </div>
                    : 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' onClick={ handleActivar } >inactivo</button>
                        </div>
                }
            </td>
            <td>
                <div className='d-grid gap-2'><button className='btn btn-secondary btn-sm' onClick={ handleFicha } >Ficha</button></div>
            </td>
            <td>
                <div className='d-grid gap-2'><button className='btn btn-secondary btn-sm' onClick={ handleCredenciales } >Credenciales</button></div>
            </td>
            <td>
                <div className='d-grid gap-2'><button className='btn btn-warning btn-sm' onClick={ handleModificar } >Modificar</button></div>
            </td>
        </tr>
    )
}
