import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux'
import { obtenerEstablecimientos } from '../../action/establecimiento'
import { obtenerUsuarios } from '../../action/usuario'
import { BotonCredencialNueva } from './BotonCredencialNueva'
import { BotonNuevoUsuario } from './BotonNuevoUsuario'
import { FichaUsuario } from './FichaUsuario'
import { FormUsuarioActualizar } from './FormUsuarioActualizar'
import { FormUsuarioCredencialActualizar } from './FormUsuarioCredencialActualizar'
import { FormUsuarioCredencialNueva } from './FormUsuarioCredencialNueva'
import { FormUsuarioInformacionActualizar } from './FormUsuarioInformacionActualizar'
import { FormUsuarioInformacionNuevo } from './FormUsuarioInformacionNuevo'
import { FormUsuarioNuevo } from './FormUsuarioNuevo'
import { TablaCredenciales } from './TablaCredenciales'
import { TablaEstablecimientos } from './TablaEstablecimientos'
import { TablaUsuarios } from './TablaUsuarios'

export const AdministradorUsuarioScreen = () => {
  const dispatch = useDispatch()
  const { uid } = useSelector( state => state.auth );
  const { establecimientos, establecimientoActivo } = useSelector( state => state.establecimiento );
  const { usuarios, usuarioActivo, credencialesUsuario, credencialUsuarioActiva } = useSelector( state => state.usuario );
  const { 
    usuarioTablaEstablecimientos, 
    usuarioTablaUsuarios, 
    usuarioFicha,
    usuarioFormNuevo, 
    usuarioFormActualizar, 
    usuarioTablaCredenciales, 
    usuarioFormCredencialNuevo, 
    usuarioFormCredencialActualizar, 
    usuarioFormInformacionNueva, 
    usuarioFormInformacionActualizar 
  } = useSelector(state => state.router);

  
  useEffect(() => {
    const obtenerEstablecimientosPrincipal = () => dispatch(obtenerEstablecimientos());
    const obtenerUsuariosPrincipal = () => dispatch(obtenerUsuarios());
    obtenerEstablecimientosPrincipal()
    obtenerUsuariosPrincipal()
    // eslint-disable-next-line
  }, []);


  if(!uid){
    setTimeout(() => {
      navigate("/login/")
    }, 1500);
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='h3 text-light'>Administrador usuario</div>
      </div>
      <div className='col-12'>
        <div className='row'>
          {usuarioTablaUsuarios &&
            <div className='col-3'>
              <BotonNuevoUsuario />
            </div>
          }
          {usuarioTablaCredenciales &&
            <div className='col-3'>
              <BotonCredencialNueva />
            </div>
          }
        </div>
      </div>
      <div className='col-12'>
        { usuarioTablaEstablecimientos && <TablaEstablecimientos establecimientos={establecimientos} /> }
        { usuarioTablaUsuarios && <TablaUsuarios establecimientoActivo={establecimientoActivo} usuarios={usuarios} /> }
        { usuarioFicha && <FichaUsuario usuarioActivo={usuarioActivo} /> }
        { usuarioFormNuevo && <FormUsuarioNuevo /> }
        { usuarioFormActualizar && <FormUsuarioActualizar usuarioActivo={usuarioActivo} /> }
        { usuarioTablaCredenciales && <TablaCredenciales usuarioActivo={usuarioActivo} credencialesUsuario={credencialesUsuario} usuarios={usuarios} establecimientos={establecimientos} establecimiento={establecimientoActivo} /> }
        { usuarioFormCredencialNuevo && <FormUsuarioCredencialNueva usuarioActivo={usuarioActivo} establecimientoActivo={establecimientoActivo} /> }
        { usuarioFormCredencialActualizar && <FormUsuarioCredencialActualizar credencialUsuarioActiva={ credencialUsuarioActiva } /> }
        { usuarioFormInformacionNueva && <FormUsuarioInformacionNuevo /> }
        { usuarioFormInformacionActualizar && <FormUsuarioInformacionActualizar /> }
      </div>
    </div>
  )
}
